import React, { useEffect, useRef } from 'react';
import { Box, Flex, VStack, Show, useMediaQuery } from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import ButtonGroup from '@components/molecules/ButtonGroup';
import TwoLineHeader from '@components/molecules/TwoLineHeader';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const HomeHero = ({
  data: { titleLineOne, titleLineTwo, leftImage, image, video, ctas }
}) => {
  const [shortViewportHeight] = useMediaQuery(`(max-height: 760px)`);
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Ensure no elements in array that are being passed to gsap tween are null.
    const elements = [
      titleRef.current,
      imageRef.current,
      buttonRef.current
    ].filter((ref) => ref !== null);

    gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current
        }
      })
      .to(elements, { opacity: 1, stagger: 0.5 }, 0.5);
  }, []);

  return (
    <Flex
      as="section"
      ref={containerRef}
      position="relative"
      minHeight={{ lg: `44rem` }}
      height={{ lg: `100vh` }}>
      <Flex
        position="relative"
        align="center"
        height="100%"
        width={{ base: `100%`, lg: `50%`, xl: `43%` }}
        pt={headerHeight}
        pb={{ base: 12, lg: 28 }}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat">
        <Wrapper>
          <VStack alignItems="normal" spacing="8">
            <Box ref={titleRef} opacity={0} willChange="opacity">
              <TwoLineHeader
                lineOne={titleLineOne}
                lineTwo={titleLineTwo}
                inHero
              />
            </Box>
            {leftImage && (
              <Box
                ref={imageRef}
                transform="auto"
                width="100%"
                mx="auto"
                maxW={shortViewportHeight ? { lg: `25rem` } : `30rem`}
                opacity={0}
                willChange="opacity">
                <Image image={leftImage} loading="lazy" />
              </Box>
            )}
            {ctas && ctas.length > 0 && (
              <Box ref={buttonRef} opacity={0} willChange="opacity">
                <ButtonGroup
                  ctas={ctas}
                  justify="center"
                  dataLayerType="home_hero"
                  buttonWidth={{ lg: `calc(50% - 1.75rem)` }}
                  size="sm"
                />
              </Box>
            )}
          </VStack>
        </Wrapper>
      </Flex>
      <Show above="lg">
        <Flex
          width={{ lg: `50%`, xl: `57%` }}
          height={{ lg: `100%` }}
          position="relative"
          _after={{
            content: { lg: `""` },
            position: `absolute`,
            top: 0,
            left: 0,
            display: `block`,
            width: `100%`,
            height: `20%`,
            bgGradient: `linear(to-b, rgba(38, 9, 80, 0.69) 0%, rgba(20, 20, 20, 0.00) 100%, rgba(0, 25, 91, 0.00) 100%)`,
            opacity: 0.75
          }}
          sx={{
            '> .gatsby-image-wrapper': {
              flexGrow: 1
            }
          }}>
          {video && (
            <Box
              as="video"
              src={video}
              controls={false}
              autoPlay
              loop
              muted
              playsInline
              objectFit="cover"
              width="100%"
              height="100%"
            />
          )}
          {!video && image && <Image image={image} loading="eager" />}
        </Flex>
      </Show>
    </Flex>
  );
};

export default HomeHero;
