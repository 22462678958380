import React from 'react';
import { AspectRatio, Flex, Box } from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';

const ImageComponent = ({ data }) => (
  <Flex alignItems="center" justifyContent="center">
    <Box overflow="hidden" mt="12" mb="20">
      <Image image={data.image} />
    </Box>
  </Flex>
);

const VideoComponent = ({ data }) => {
  const { videoURL, videoTitle } = data;
  return (
    <AspectRatio ratio={16 / 9} overflow="hidden" mb="12">
      <Box
        as="video"
        src={videoURL}
        title={videoTitle}
        controls
        playsInline
        objectFit="cover"
        width="100%"
        height="100%">
        {videoTitle}
      </Box>
    </AspectRatio>
  );
};

const MediaComponents = {
  'media.image': ImageComponent,
  'media.video': VideoComponent
};

const Media = ({ data }) => {
  const { strapiComponent, ...props } = data;
  const TagName = MediaComponents[String(strapiComponent)];

  if (typeof TagName === `undefined`) {
    return (
      <div key={`media-component-${strapiComponent} `}>
        Component <b>{strapiComponent}</b> not found
      </div>
    );
  }

  return (
    <Wrapper maxWidth="61.625rem">
      <TagName
        key={`media-component-${strapiComponent}`}
        data={{ strapiComponent, ...props }}
      />
    </Wrapper>
  );
};

export default Media;
